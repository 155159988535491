<template>
  <div class="CitasForm">
    <section class="technical-drawing__section empresas-login">
      <header class="empresas-login__header">
        <div class="empresas-login__inner container">
          <div class="empresas-login__header-content">
            <h2
              class="empresas-login__heading heading"
              v-text="'Programa tu cita médica'"
            />
            <div
              class="empresas_home__item-text"
              v-text="'Para poder solicitar tu cita es necesario llenes los siguientes campos'"
            />
            <v-card
              flat
              :loading="cargando"
              :disabled="cargando"
            >
              <ul class="empresas_home__list">
                <v-row class="mt24">
                  <v-col
                    cols="12"
                    id="datos_cita"
                  >
                    <li class="empresas_home__item">
                      <h3
                        class="empresas_home__item-heading"
                        v-text="'Selecciona tu horario'"
                      />
                      <v-row class="mt24">
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                        >
                          <v-select
                            :error-messages="errorsCita.tipo_cit"
                            filled
                            hint="Puedes escoger entre cita en nuestras clínicas o en tu domicilio"
                            :items="tiposCitas"
                            label="Tipo de consulta"
                            persistent-hint
                            v-model="cita.tipo_cit"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                        >
                          <autocomplete-clinicas
                            :disabled="!cita.tipo_cit || cita.tipo_cit ==='domicilio'"
                            :error="errorsCita.id_cli_cit"
                            hint="Selecciona la clínica de tu conveniencia"
                            label="Clínica"
                            v-model="cita.id_cli_cit"
                            @change="clinica = $event;"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                        >
                          <date-picker
                            :disabled="!cita.id_cli_cit"
                            :error-messages="errorsCita.fecha_cit"
                            sinceToday
                            label="Fecha para tu cita"
                            v-model="cita.fecha_cit"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                        >
                          <v-select
                            :disabled="!cita.fecha_cit || cargando"
                            :error-messages="errorsCita.horario_cit"
                            :items="horarios"
                            item-text="hora"
                            item-value="cita"
                            filled
                            label="Hora de tu cita"
                            :loading="cargando"
                            v-model="cita.horario_cit"
                            @change="_getHora"
                          >
                            <template v-slot:no-data>
                              <v-list-item ripple>
                                <v-list-item-content>
                                  <v-list-item-title v-text="'No tenemos horarios disponibles la fecha seleccionada'" />
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                          </v-select>
                        </v-col>
                        <v-col
                          cols="12"
                          v-if="cita.tipo_cit"
                        >
                          <v-card
                            flat
                            color="primary"
                            dark
                            class="mb24"
                          >
                            <v-card-title>
                              La consulta {{cita.tipo_cit === 'clinica'? 'en clínica': 'a domicilio'}} tiene un precio de {{$nF(precios[cita.tipo_cit])}}*
                            </v-card-title>
                            <v-card-text>
                              *El precio listado solo incluye la consulta clínica, el precio final puede variar según los procedimientos y servicios médicos adicionales que se puedan llegar a otorgar.
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                      <br>
                      <h3
                        class="empresas_home__item-heading"
                        v-text="'Datos personales'"
                      />
                      <v-row class="mt24">
                        <v-col
                          cols="12"
                          sm="12"
                          md="4"
                        >
                          <v-text-field
                            :disabled="!cita.horario_cit"
                            :error-messages="errors.nombre_pac"
                            filled
                            label="Nombre*"
                            v-model="paciente.nombre_pac"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                        >
                          <v-text-field
                            :disabled="!cita.horario_cit"
                            :error-messages="errors.ape_pat_pac"
                            filled
                            label="Apellido paterno*"
                            v-model="paciente.ape_pat_pac"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                        >
                          <v-text-field
                            :disabled="!cita.horario_cit"
                            :error-messages="errors.ape_mat_pac"
                            filled
                            label="Apellido materno"
                            v-model="paciente.ape_mat_pac"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                        >
                          <v-select
                            :disabled="!cita.horario_cit"
                            :error-messages="errors.sexo_pac"
                            filled
                            :items="[{text:'Femenino',value:'femenino'},{text:'Masculino',value:'masculino'},{text:'Otro / Prefiero no especificar',value:'prefiero no especificar'}]"
                            label="Sexo"
                            v-model="paciente.sexo_pac"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                        >
                          <v-text-field
                            :disabled="!cita.horario_cit"
                            :error-messages="errors.fecha_nac_pac"
                            filled
                            hint="día-mes-año"
                            inputmode="numeric"
                            label="Fecha de nacimiento"
                            pattern="[0-9]*"
                            persistent-hint
                            placeholder="dd-mm-aaaa"
                            prepend-inner-icon="today"
                            v-mask="'##-##-####'"
                            v-model="paciente.fecha_nac_pac"
                            @blur="_validateFechaNacimiento"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                        >
                          <v-text-field
                            :disabled="!cita.horario_cit"
                            :error-messages="errors.cel_pac"
                            hint="Teléfono a 10 dígitos"
                            inputmode="numeric"
                            pattern="[0-9]*"
                            filled
                            label="Teléfono móvil"
                            placeholder="(614) 411-9500"
                            prepend-inner-icon="phone"
                            v-mask="'(###) ###-####'"
                            v-model="paciente.cel_pac"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          v-if="cita.tipo_cit==='domicilio'"
                        >
                          <g-maps-autocomplete-places
                            :disabled="!cita.horario_cit"
                            :error="errorDomicilio"
                            label="¿A donde acudiremos?"
                            @change="cita.domicilio_cit = $event"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-spacer />
                        <v-btn
                          :disabled="!cita.horario_cit"
                          style="margin-right:12px;"
                          class="primary"
                          v-text="'Programar cita'"
                          @click="_validateDatosCita"
                        />
                      </v-row>
                    </li>
                  </v-col>
                </v-row>
              </ul>
            </v-card>
          </div>
        </div>
      </header>
    </section>
    <v-dialog
      v-model="showDialogConfirmarCita"
      max-width="500"
      persistent
    >
      <v-card
        :loading="cargando"
        :disabled="cargando"
      >
        <v-card-title v-text="'Por favor confirma los datos de tu cita'" />
        <v-list-item-group>
          <v-list-item>
            <v-list-item-icon>
              <v-icon v-text="'today'" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                class="nowrap"
                v-text="
              (cita.tipo_cit ==='clinica'?'Cita en la clínica '+clinica.clinica: 'Cita en tu domicilio ')+fechaFormateada"
              />
              <v-list-item-subtitle v-text="cita.tipo_cit === 'clinica'?'Lugar y fecha':'Fecha'" />
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-list-item>
            <v-list-item-icon>
              <v-icon v-text="'attach_money'" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                class="nowrap"
                v-text="$nF(precios[cita.tipo_cit])"
              />
              <v-list-item-subtitle v-text="'Precio de la consulta'" />
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-list-item>
            <v-list-item-icon>
              <v-icon v-text="'account_circle'" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                class="nowrap"
                v-text="paciente.nombre_pac+' '+paciente.ape_pat_pac+' '+paciente.ape_mat_pac"
              />
              <v-list-item-subtitle v-text="'Paciente'" />
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-list-item>
            <v-list-item-icon>
              <v-icon v-text="'phone'" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                class="nowrap"
                v-text="paciente.cel_pac"
              />
              <v-list-item-subtitle v-text="'Teléfono de contacto'" />
            </v-list-item-content>
          </v-list-item>
          <div v-if="cita.tipo_cit==='domicilio'">
            <v-divider />
            <v-list-item>
              <v-list-item-icon>
                <v-icon v-text="'place'" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="nowrap">
                  {{_getLugar()}}
                </v-list-item-title>
                <v-list-item-subtitle v-text="'Domicilio'" />
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list-item-group>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            v-text="'Cancelar'"
            @click="showDialogConfirmarCita = false"
          />
          <v-btn
            class="primary"
            v-text="'Programar cita'"
            @click="_saveCita"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import forms from "@/mixins/forms.js";
import AutocompleteClinicas from "@/components/forms/autocompletes/AutocompleteClinicas.vue";
import DatePicker from "@/components/forms/pickers/DatePicker.vue";
import GMapsAutocompletePlaces from "@/components/forms/gMaps/gMapsAutocompletePlaces.vue";
export default {
  name: "CitasForm",
  mixins: [forms],
  components: { AutocompleteClinicas, DatePicker, GMapsAutocompletePlaces },
  data() {
    return {
      paciente: {
        nombre_pac: null,
        ape_pat_pac: null,
        ape_mat_pac: null,
        sexo_pac: null,
        fecha_nac_pac: null,
        cel_pac: null,
      },
      errors: {
        nombre_pac: null,
        ape_pat_pac: null,
        ape_mat_pac: null,
        sexo_pac: null,
        fecha_nac_pac: null,
        cel_pac: null,
      },
      cita: {
        id_cli_cit: null,
        fecha_cit: null,
        horario_cit: null,
        tipo_cit: null,
        domicilio_cit: null,
      },
      errorsCita: {
        id_cli_cit: null,
        fecha_cit: null,
        horario_cit: null,
        tipo_cit: null,
      },
      errorDomicilio: null,
      horarios: [],
      sexos: ["femenino", "masculino", "prefiero no especificar"],
      tiposCitas: [
        { text: "Consulta en clínica", value: "clinica" },
        { text: "Consulta a domicilio", value: "domicilio" },
      ],
      pacientesSimilares: [],
      showDialogConfirmarCita: false,
      clinica: { clinica: null }, //nombre de la clinica que se mostrara
      horario: null,
      fechaFormateada: null,
      precios: {
        clinica: 0,
        domicilio: 0,
      },
    };
  },
  methods: {
    _handleResponseExiste({ pacientes }) {
      if (pacientes.length >= 1) {
        this.pacientesSimilares = pacientes;
        this.showDialogConfirmarCita = true;
      }
    },
    _getHorariosCitas() {
      const { fecha_cit, id_cli_cit, tipo_cit } = this.cita;
      if (tipo_cit === "domicilio") {
        this.cita.id_cli_cit = "1";
      }
      if (fecha_cit && id_cli_cit && tipo_cit) {
        this.horarios = [];
        this._getThings(
          "citas/horarios/?fecha=" +
            fecha_cit +
            "&clinica=" +
            id_cli_cit +
            "&tipo=" +
            tipo_cit,
          "horarios"
        );
      }
    },
    _validateDatosCita() {
      if (
        this._validateAll("cita", "errorsCita") &&
        this._validateAll("paciente") &&
        this._validateFechaNacimiento &&
        this._validateTel() &&
        this._validateDomicilio()
      ) {
        this.showDialogConfirmarCita = true;
      } else {
        this.$emit("msg", this.$lleneCampos);
      }
    },
    _validateTel() {
      if (this.paciente.cel_pac.replace(/\D/g, "").length != 10) {
        const error = "Ingresa un teléfono a 10 dígitos";
        this.errors.cel_pac = error;
        this.$emit("msg", error);
        return false;
      } else {
        this.errors.cel_pac = null;
        return true;
      }
    },
    _validateDomicilio() {
      if (this.cita.tipo_cit === "domicilio") {
        if (
          !this.cita.domicilio_cit ||
          typeof this.cita.domicilio_cit != "object"
        ) {
          const error = "Por favor ingresa el domicilio a donde acudiremos";
          this.errorDomicilio = error;
          this.$emit("msg", error);
          return false;
        } else {
          this.errorDomicilio = null;
          return true;
        }
      }
      return true;
    },
    _validateFechaNacimiento() {
      let date = this.$Datetime.fromFormat(
        this.paciente.fecha_nac_pac,
        "dd-MM-yyyy"
      );
      if (
        date.isValid &&
        date.diffNow("years").years > -120 &&
        date.diffNow("years").years < 0
      ) {
        this.errors.fecha_nac_pac = null;
        return true;
      } else {
        const error = "Ingresa una fecha de nacimiento válida";
        this.errors.fecha_nac_pac = error;
        this.$emit("msg", error);
        return false;
      }
    },
    _getHora() {
      const h = this.cita.horario_cit;
      if (h) {
        let idx = this.horarios.findIndex((e) => {
          return e.cita == h;
        });
        if (idx >= 0) {
          this.horario = this.horarios[idx].hora + ":00";
          this._formatFecha();
        }
      }
    },
    _formatFecha() {
      this.fechaFormateada = this.$Datetime
        .fromISO(this.cita.fecha_cit + "T" + this.horario)
        .setLocale("es-Mx")
        .setZone("America/Mexico_City")
        .toFormat("' el día' d 'de' LLL 'a las' h:mm a");
    },
    _getLugar() {
      return this.cita.domicilio_cit ? this.cita.domicilio_cit.description : "";
    },
    _saveCita() {
      this.cargando = true;
      let cita = this.cita;
      cita.domicilio_cit = JSON.stringify(cita.domicilio_cit);
      let data = {
        cita: cita,
        paciente: this.paciente,
      };
      this._saveAll("citas", data, "id", "_handleResponseCita");
    },
    _handleResponseCita(res) {
      this.$emit("msg", res.msg);
      this.$router.push("/confirmacion-de-cita/" + res.id);
    },
  },
  created() {
    this._getThings("citas/precios", "precios");
    this.$emit("visible", false);
    this.$emit("classes", "");
  },
  beforeDestroy() {
    this.$headers.headers.Token = null;
  },
  watch: {
    cita: {
      deep: true,
      immediate: true,
      handler: "_getHorariosCitas",
    },
  },
};
</script>