<template>
  <div class="AutocompleteClinicas">
    <v-autocomplete
      v-if="!isSelect"
      v-model="value"
      append-icon="search"
      :disabled="disabled"
      :error-messages="error"
      filled
      :hint="hint"
      item-text="clinica"
      item-value="id"
      :items="clinicas"
      :label="label ? label : 'Buscar base médica'"
      :persistent-hint="hint?true:false"
      @change="_searchAndSendClinica"
    >
      <template v-slot:item="data">
        <v-list-item-content>
          <v-list-item-title v-text="data.item.clinica" />
          <v-list-item-subtitle v-text="data.item.domicilio" />
        </v-list-item-content>
      </template>
    </v-autocomplete>
    <v-select
      v-else
      v-model="value"
      :error-messages="error"
      filled
      :hint="hint"
      item-text="clinica"
      item-value="id"
      :items="clinicas"
      :label="label ? label : 'Seleccionar base médica'"
      :persistent-hint="hint?true:false"
      @change="_searchAndSendClinica"
    />
  </div>
</template>
<script>
import forms from "@/mixins/forms.js";
export default {
  name: "AutocompleteClinicas",
  mixins: [forms],
  props: {
    disabled: Boolean,
    error: String,
    hint: String,
    isSelect: Boolean,
    label: String,
    value: String,
  },
  data() {
    return {
      clinicas: [],
    };
  },
  methods: {
    _searchAndSendClinica(a) {
      this.$emit("input", a);
      let i = this.clinicas.findIndex(function (b) {
        return b.id === a;
      });
      if (i >= 0) {
        this.$emit("change", this.clinicas[i]);
      }
    },
  },
  created() {
    this._getThings("data/clinicas", "clinicas");
  },
};
</script>